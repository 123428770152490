.footerBackground {
    display: block;
    top:0;
    background: #181818;
    height: 55px;
    width: 100%;
    z-index: 100;
    text-align: right;
    padding: 14px;
}

.footerTMContainer {
    float: left;
    padding-left: 2vw;
}

.footerTM {
    color: white;
    font-size: 18px;
}

.footerTM:hover {
    color: #d9ab73;
    text-decoration: none;
}

.footerNav {
    float: right;
    margin-right: 1vw;
}

.footerNavItem {
    
    color: white;
    
    text-decoration: none;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    
    padding-left: 25px;
 }

 .footerNavItem:hover {
    color: #d9ab73;
    text-decoration: none;
 }

 #spacer:hover {
     text-decoration: none;
 }

 @media screen and (max-width: 775px) {
    .footerTM {
        font-size: 10px;
    }

    .footerNavItem {
        font-size: 18px;
    }
 }
 
 @media screen and (max-width: 490px) {
    .footerTM {
        font-size: 10px;
    }

    .footerNavItem {
        font-size: 14px;
        padding-left: 15px;
    }
 }

 @media screen and (max-width: 425px) {
    .footerTM {
        font-size: 10px;
    }

    .footerNavItem {
        font-size: 12px;
        padding-left: 10px;
    }
 }

 @media screen and (max-width: 375px) {
    .footerTM {
        font-size: 8px;
    }

    .footerNavItem {
        font-size: 8px;
        padding-left: 5px;
    }
 }