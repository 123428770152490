/* @font-face {
  font-family: "Linux Libertine";
  src: local("Linux Libertine"),
    url(./assets/fonts/LinLibertine_aDRS.ttf) format("truetype");
} */

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow-x: hidden;
}

@media only screen and (max-width: 425px) {
  body {
    position: relative;
    overflow-x: hidden;
  }

  html {
    position: relative;
    overflow-x: hidden;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #181818;
}

::-webkit-scrollbar-thumb {
  background: #d9aa75;
  border-radius:5px;
  border:3px solid #181818;
}

::-webkit-scrollbar-track {
  background: #181818;
}