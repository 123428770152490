#ageGateModalContainer {
  padding-top: 30px;
  padding-bottom: 30px;
  

  background-color: #181818;
  border-radius: 20px;
  display: fixed;

  position: fixed;
  /* bottom: -150vh; */
  transition: all 150ms east-in-out;

  top:50%;
  left:50%;
  transform: translate(-50%,-50%);


  box-shadow: inset 0px 0px 5px rgba(0,0,0,0.16), 0px 0px 5px rgba(0,0,0,0.45);

  z-index:99;
}

.ageLogoContainer {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ageGateModalContent {
  padding-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 50px;
}

.ageGateHeadline {
  text-align: center;
  color: white;

  font-size: 18px;
  
  margin-top: 5px;
  margin-bottom: 15px;
  
}

.ageGateCopyPadding {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ageGateButtons {
  text-align: center;

  /* padding-top: 10px;
  padding-bottom: 10px; */

}

.yesNoButton {
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;

  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;

  text-align: center;
}

#yesAgeGate {
  float: right;
}

#noAgeGate {
  float: left;
}

#yesAgeGate:hover {
  color: #181818;
  background-color: #d9ab73;
}

#noAgeGate:hover {
  color: #181818;
  background-color: #d9ab73;
}

.termsAndConditions {
  text-align: center;
  color: white;

  font-size: 14px;

  margin-top: 20px;
  margin-bottom: 10px;
}

.termsAndConditions a {
  color: #d9ab73;
}

@media screen and (max-width: 900px){
  .ageLogoContainer img {
    width: 20em;
  }

  .ageGateHeadline {
    font-size: 16px;
  }

  .yesNoButton {
    margin-left: 0px;
    margin-right: 0px;
  }

  .termsAndConditions {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px){

  .ageGateModalContent {
    padding-left: 70px;
    padding-right: 70px;
  }
  
}

@media screen and (max-width: 500px){

  .ageGateModalContent {
    padding-left: 50px;
    padding-right: 50px;
  }
  
}

@media screen and (max-width: 425px){
  .ageLogoContainer img {
    width: 15em;
  }

  .ageGateModalContent {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .ageGateHeadline {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .termsAndConditions {
    font-size: 12px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .yesNoButton {
    font-size: 18px;
    margin-left: 0px;
    margin-right: 0px;
  
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
  
    text-align: center;
  }
  
}