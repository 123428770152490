


.termsOfUseContainer {
  overflow-x: hidden;    
  width: 100%;
    background: #181818;
    z-index: 1;
}

.termsOfUseLogoContainer {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 15em;
}

.termsLogo {
  position: relative;
  width: 100%;
  margin-top: 3vw;
}

.termsOfUseTextContainer {
  margin-top: 2vw;
  margin-left: 20vw;
  margin-right: 20vw;
}

.termsOfUseContainer h1 {
  
  color: #d9ab73;
  font-family: "Crimson Text Pro", serif;
  padding: 1vw;
  font-size: 48px;

}

.termsOfUseContainer h3 {
  color: #d9ab73;
  font-family: "Crimson Text Pro", serif;
  padding: 1vw;
  font-size: 24px;
  margin-top: 2vw;
  /* margin-bottom: 2vw; */
}

.termsOfUseContainer  p {
  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 1vw;
  font-size: 16px;
}

.whiteText {
  color: whitesmoke;
  text-decoration: none;;
}


.yumaBorder {
    position: relative;
    /* z-index: 100; */
    padding: 0 !important;
}
  
  
.yumaBorder::before {
  content: '';
  position: absolute;
  border: 1px solid #d9ab73;
  left: 0;
  right: 0;
  transform: scale(0.97,0.94);
  width: 100%;
  height: 100%;
  /* pointer-events: none; */
  
}

.yumaBorder::after {
  content: '';
  position: absolute;
  border: 1px solid #d9ab73;
  left: 0;
  top: 0;
  right: 0;
  transform:scale(0.95,0.98);
  width: 100%;
  height: 100%;
  /* pointer-events: none; */
}

@media screen and (max-width: 768px) {

  .termsOfUseTextContainer {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .termsOfUseContainer h1 {
    font-size: 40px;
  }

  .termsOfUseContainer h3 {
    font-size: 20px;
  }

}

@media screen and (max-width: 768px) {
  .termsOfUseContainer h1 {
    margin-top: 5vw;
    font-size: 32px;
  }

}

@media screen and (max-width: 425px) {
  .termsOfUseContainer h1 {
    margin-top: 5vw;
    margin-bottom: 5vw;
  }

}