
.containerPadding {
  padding: 0px;
  background-color: transparent !important;
}

.stickyContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 0px !important;
  padding-top: 20px;
  background-color: rgb(23, 23, 23) !important;
  z-index:  90;
}

.logo {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 100% !important;
  max-height: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  stroke-width: 0;
}

.backDiv {
  position: relative;
}

.backButton {
  position: absolute;
  width: 20px;
  top: 40%;
  left: 5vw !important;
  background: transparent;
  border: transparent;
  color: #d9ab73;
  padding: 0px !important;
  /* stroke-width: 0 !important;
  outline: 0 !important; */
}

.backButton:focus {
  outline: none;
}

.backArrow {
  /* stroke-width: 0 !important;
  outline:0 !important; */
}

@media (max-width: 768px) {
  .backButton {
    left: 5vh;
  }
}

.buttonContainer {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-bottom: 15px;
}

.button {
  border: 1px solid rgb(217,170,117,.5);
  background: #191919;
  border-radius: 10px;
  color: rgb(217,170,117);
  font-family: "Montserrat",sans-serif;
  font-size: 89%;
  margin: auto;
  min-width: auto !important;
  font-weight: bolder;
  outline: none;
}

.button:hover {
  cursor: pointer !important;
  background: #d9ab73;
  color: #181818;
}

.button:focus {
  outline: none;
}

.tri {
  padding: 20px 1vw 20px 1vw !important;
  min-width: 14vh;
  margin-right: 3px;
  margin-left: 3px;
  font-family: "Montserrat", sans-serif;
}

.duo {
  padding: 20px 2vw 20px 2vw !important;
  min-width: 18vh;
  margin-right: 3px;
  margin-left: 3px;
  outline: none;
}

/* CONTACT INFO */

.phoneAndAddress {
  color: #f2e6d5;
  margin: auto;
  text-align: Center;
  margin-top: 15px;
  width: 85%;
  margin-bottom: 15px;
  font-family: "Crimson Pro", serif;
}

.storeImage {
  width: 100%;
}

.innerPhoneAndAddress {
  border-top: 1px solid rgb(242, 230, 213, 0.3);
  border-bottom: 1px solid rgb(242, 230, 213, 0.3);
}

.phoneLink {
  color: inherit;
}
.phoneLink:hover {
  color: #d9ab73;
}
.hoverAddress {
  cursor: pointer;
}
.hoverAddress:hover {
  color: #d9ab73;
}

.phoneIcon {
  color: #d9ab73;
  margin-bottom: 10px;
  margin-top: 15px;
}

.locationIcon {
  color: #d9ab73;
  margin-top: 20px;
  margin-bottom: 10px;
}

.phoneNumber {
  margin-bottom: 15px;
}

/* SOCIAL MEDIA */

.socialMediaContainer {
  margin-top: 10px;
  margin-bottom: 20px;

}

.socialIcon {
  padding-left: 5px;
  padding-right: 5px;
  transition: all .2s ease-in-out; 
}

.socialIcon:hover {
  transform:scale(1.1);
}

/* STORE HOURS */

.hoursContainer {
  text-align: center;
  width: 85%;
  margin: auto;
  font-size: 1.5rem;
  margin-bottom: 25px;
}
.hoursContainer h4 {
  color: #d9ab73;
  font-size: 2.5rem;
  font-family: "Crimson Pro", serif;
}

.storeTimes {
  font-family: "Montserrat", sans-serif;
  color: #f2e6d5;
}

.storeDescription {
  width: 85%;
  color: #f2e6d5;
  text-align: center;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 20px;
}
.storeDescription h4 {
  border-top: 1px solid rgb(242, 230, 213, 0.3);
  border-bottom: 1px solid rgb(242, 230, 213, 0.3);
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 2.5rem;
  font-family: "Crimson Pro", serif;
  color: #d9ab73;
}
.storeDescription p {
  width: 85%;
  margin-bottom: 20px;
  color: #f2e6d5;
  font-family: "Montserrat", sans-serif;
  /* font-style: italic; */
  text-align: left;
  font-size: 17px;
  font-weight: 400;
}

