@import url(https://fonts.googleapis.com/css2?family=Crimson+Text&family=Montserrat:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Crimson+Text&family=Montserrat:wght@200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200&display=swap);
/* @font-face {
  font-family: "Linux Libertine";
  src: local("Linux Libertine"),
    url(./assets/fonts/LinLibertine_aDRS.ttf) format("truetype");
} */

body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  overflow-x: hidden;
}

@media only screen and (max-width: 425px) {
  body {
    position: relative;
    overflow-x: hidden;
  }

  html {
    position: relative;
    overflow-x: hidden;
  }
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background: #181818;
}

::-webkit-scrollbar-thumb {
  background: #d9aa75;
  border-radius:5px;
  border:3px solid #181818;
}

::-webkit-scrollbar-track {
  background: #181818;
}
/* body {
  position: absolute;
} */

.holder {
  width: 100vw;
  /* height: calc(100vh - 70px); */
  overflow-x: hidden;
  overflow-y: auto;
  background: #181818;
  /* z-index: -1; */
}

#ageGateBackground {
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background: rgb(0, 0, 0, 0.75);
  z-index: 99;
  transition: opacity 0.5s;
}

.darkBackground {
  background-color: #181818 !important;
}

.separate-containers .inside-article {
  background-color: white;
}

.footer-widgets .widget-title {
  margin-bottom: 10px;
}
.inside-site-info {
  font-size: 12px;
}

#page {
  padding: 0px;
}

/* --------- */
/* HOME PAGE */
/* --------- */

.yumaBorder {
    position: relative;
    /* z-index: 100; */
    padding: 0 !important;
  }
  
  
  .yumaBorder::before {
    content: '';
    position: absolute;
    border: 1px solid #d9ab73;
    left: 0;
    right: 0;
    transform: scale(0.97,0.94);
    width: 100%;
    height: 100%;
    /* pointer-events: none; */
    
  }
  
  .yumaBorder::after {
    content: '';
    position: absolute;
    border: 1px solid #d9ab73;
    left: 0;
    top: 0;
    right: 0;
    transform:scale(0.95,0.98);
    width: 100%;
    height: 100%;
    /* pointer-events: none; */
  }
  
  /* INITIAL PAGE SETUP */
  #hideOnDesktop { 
    display: none;
  }
  
  #mobileHeroView {
    display: none;
  }
  
  #desktopHeroView {
    display: flex;
  }
  
  #desktopHeroOutline {
    display:flex;
  }
  
  #tabletHeroOutline {
    display: none;
  }
  /* #mobileTabletContentHolder {
    display: none;
  } */
  
  #mobileHeroOutline425 {
    display: none;
  }
  
  .darkBackground {
    /* padding-top: 100px; */
    background-color: #181818 !important;
  }
  
  /* HOME: HERO SECTION */
  
  section.hero {
    position: relative;
    height: 100%;
    /* width: 100vw; */
    
  }
  section.hero .heroCol {
    width: 100%;
    position: relative;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    z-index: 2;
    min-height: 40vh;
  
  }
  
  /* section.hero svg.rotated {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    stroke-width: 0.1px;
    z-index: 2;
  
  } */
  
  section.hero .storeFinderButton {
    background-color: rgba(201, 76, 76, 0);
    border-radius: 3px;
    line-height: 3.2vw;
    color: #d9ab73;
    font-family: "Montserrat", sans-serif;
    font-size: 1.14vw;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    max-width: 24vw;
    max-height: 3.45vw;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  
    margin-left: auto;
    margin-right: auto;
    margin-top: 4vh;
    
    padding: 0 20px 0 20px;
    border: 2px solid #d9ab73;
    border-radius: 12px;
    text-decoration: none;
  
    z-index: 999;
  }
  
  .storeFinderButton:hover {
    background-color: #d9ab73 !important;
    color: #232323 !important;
    cursor: pointer !important;
  }
  
  section.hero .logo {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
  
    margin-left: 10vw;
    /* margin-right: auto; */
  
  }
  
  section.hero .heroCol #logoCol {
    position: relative;
    
  }
  
  section.hero .heroCol #imageCol {
    position: relative;
    margin-left: 5vw;
  }
  
  
  
  .displayLogoContainer {
    /* width: 100%; */
    position: relative;
    
  }
  
  .displayLogo {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
  }
  
  .displayImageContainer {
    position: relative;
    width: 100%;
    text-align: center;
  }
  
  .displayImage {
    width: 50% !important;
    margin-top: 50px; 
    margin-left: 50px; 
  }
  
  .displayButtonContainer {
    position: relative;
    width: 100%;
    text-align: center;
  
    margin-top: 20px;
  }
  
  /* HOME: MASSIVE PRODUCT SECTION */
  
  .QuoteBanner {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
  
  }
  
  .QuoteBanner .mobile-only {
    display: none;
  }
  
  .QuoteBanner img {
    width: 125%;
    background-attachment: fixed;
  }
  
  .QuoteBanner article {
    right: 7vw;
    margin: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  
    justify-content: center;
    margin-left: auto;
    z-index: 10;
    max-width: 70%;
  }
  
  .QuoteBanner article h2 {
    font-family: "Crimson Text Pro", serif;
    font-size: 2.4vw;
    font-weight: 900;
    line-height: 3.95vw;
    margin: 0 0 5px;
    /* max-width: 525px; */
    text-align: center;
    color: #d9ab73;
  
  }
  
  .QuoteBanner article p {
    font-family: "Montserrat", sans-serif;
    font-size: 1vw;
    line-height: 1.75vw;
    /* margin: 0 0 3vw; */
    /* max-width: 450px; */
    color: #f2e6d5;
    text-align: center;
    /* margin-bottom: 20px; */
  }
  
  .QuoteBanner article a {
    background-color: rgba(201, 76, 76, 0);
    border-radius: 3px;
    line-height: 3.2vw;
    color: #d9ab73;
    font-family: "Montserrat", sans-serif;
    font-size: 1.14vw;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    /* max-width: 14vw;
    max-height: 3.45vw; */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
    padding: 0 20px 0 20px;
    border: 2px solid #d9ab73;
    border-radius: 12px;
  }
  
  .QuoteBanner article a:hover {
    background-color: #d9ab73;
    color: #232323;
    cursor: pointer;
  }
  
  .QuoteBanner .article_text {
    position: relative;
    /* right: 0%; */
    /* top: 30%; */
  }
  
  
  
  /* HOME: CALL-TO-SHOP SECTION */
  
  .ShopNow {
    background-color: #181818;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: scale-down; /* Resize the background image to cover the entire container */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position:relative;
    /* overflow: hidden; */
    /* height: 48.65vw; */
    /* height: 40vw; */
    /* max-height: 700px; */
  }
  
  section.ShopNow svg.rotated {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    /* max-height: 1500px; */
    stroke-width: 0.1px;
  }
  
  .ShopNow .col {
    width: 40%;
    position: relative;
    min-height: 30vw;
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    padding-top: 20px;
  }
  
  .ShopNow .image-col {
    width: 35%;
    position: relative;
    min-height: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 8px; */
  }
  
  .ShopNow img {
    max-width: 100%;
    height: 22vw;
    position: absolute;
    max-height: 100%;
  }
  
  .ShopNow article {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content:justify;
    max-width: 100%;
    padding-top: 20%;
  }
  
  .ShopNow article h2 {
    font-family: "Crimson Text Pro", serif;
    font-size: 2.4vw;
    font-weight: 900;
    line-height: 3.95vw;
    margin: 0 0 2px;
    /* max-width: 525px; */
    text-align: center;
    color: #d9ab73;
  }
  
  .ShopNow article p {
    font-family: "Montserrat", sans-serif;
    font-size: 1vw;
    line-height: 1.75vw;
    margin: 0 0 3vw;
    /* max-width: 450px; */
    color: #f2e6d5;
    text-align: center;
  }
  
  .ShopNow article a {
    background-color: rgba(201, 76, 76, 0);
    border-radius: 3px;
    line-height: 3.2vw;
    color: #d9ab73;
    font-family: "Montserrat", sans-serif;
    font-size: 1.14vw;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    max-width: 14vw;
    max-height: 3.45vw;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
    padding: 0 20px 0 20px;
    border: 2px solid #d9ab73;
    border-radius: 12px;
  }
  
  .ShopNow article a:hover {
    background-color: #d9ab73;
    color: #232323;
    cursor: pointer;
    text-decoration: none;
  }
  
  .ShopNow .mobile-only {
    display: none;
  }
  
  .ShopNow .non-mobile-only {
    display: block;
  }
  
  
  /* HOME: 3 MAIN PRODUCTS */
  
  .threeMainProducts a {
    text-decoration: none;
  }
  
  .threeMainProducts a:hover {
    text-decoration: underline;
    -webkit-text-decoration-color: #d9ab73;
            text-decoration-color: #d9ab73;
  }
  .threeMainProducts {
    position: relative;
    background-color: #181818;
  
    padding-top: 40px;
    padding-bottom: 20px;
  }
  
  .threeImageCarousel {
    position: relative;
    /* padding-left: 10%;
    padding-right: 10%; */
    text-align: center;
  
    margin-left: 15px;
  }
  
  /* .productSquareColumn{
    padding-left:10px;
    padding-right:10px;
  
  } */
  
  .threeProductCategoryImages {
    position: relative;
    /* padding: 50%; */
  
    /* padding-top: 40px; */
    padding-left: 50px;
    padding-right: 50px;
    /* padding-bottom: 40px; */
  
    width: 100%;
  }
  
  .threeProductCategoryHeadline {
    font-family: 'Crimson Text Pro', serif;
    font-weight: bold;
    font-size: 2vw;
    color: rgb(217,171,115);
  }
  
  .flowerBox {
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  
  .edibleBox {
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  
  .concentrateBox {
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  
  #flowerImage {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  #flowerImageHover {
    position: absolute;
    top: 0px; 
    left: 0px;
  }
  
  #edibleImage {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  #edibleImageHover {
    position: absolute;
    top: 0px; 
    left: 0px;
  }
  
  #concentrateImage {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  #concentrateImageHover {
    position: absolute;
    top: 0px; 
    left: 0px;
  }
  
  .overlay {
    display: inline-block;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: .5s ease;
  }
  
  .flowerBox .overlay:hover {
    opacity: 1;
  }
  
  .edibleBox .overlay:hover {
    opacity: 1;
  }
  
  .concentrateBox .overlay:hover {
    opacity: 1;
  }
  
  
  
  /* HOME: CATEGORY SECTION */
  
  section.Categories {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    
    position: relative;
    /* overflow: hidden;
    height: 50vh; */
    max-height: 700px;
    width: 100%;
  }
  
  section.Categories .yumaBorder {
    width: 100%
  }
  
  section.Categories svg.rotated {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    stroke-width: 0.1px;
    padding-bottom: 20px;
  }
  
  section.Categories .article-container {
    width: 100%;
    
    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-left: 5vw;
    padding-right: 5vw;
  
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  section.Categories .article-container article {
    min-width: 15.5vw;
  
    justify-content: center;
  }
  
  .yellowText {
    color: #d9ab73;
  }
  
  .lightText {
    color: #f2e6d5 !important;
  }
  
  section.Categories .mobile-only {
    display: none;
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
  }
  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  
  section.Categories article header {
    font-size: 4.1vw;
    font-weight: 200;
    line-height: 5.2vw;
    text-align: center;
    width: 100%;
    display: block;
    font-family: "Crimson Text Pro", serif;
  }
  
  section.Categories article ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
  
  section.Categories article ul li {
    display: block;
    text-align: center;
  }
  
  section.Categories article ul li a {
    color: #f2e6d5 !important;
    font-size: 1.11vw;
    font-weight: 400;
    line-height: 4vh;
    text-align: center;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
  }
  
  #svgCategorySeparator {
    width: 100px;
    /* height: 100%; */
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  /* top: calc(10vh - 20px); */
  /* .verticalLineSeperator {
    width: 20px;
    height: 100px;
    position: relative;
    top: calc(10% + 10px);
    left: calc(10% + 10px);
    border: 1px solid #d9ab73;
    opacity: .5;

    margin-top: 1vh;
    margin-bottom: 1vh;
  } */

  /* .verticalLineSeperator::after {
    position: absolute;
    top: -40px;
    bottom: -40px;
    left: calc(50% - 1px);
    background: #d9ab73;
    width: 2px;
    content: '';
  } */
  
  #lineBox {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 100%;
    stroke-width: 0.5;
    z-index: 10;
  }
  #list-items {
    margin: auto;
  }
  
  
  
  /* HOME: LOYALTY PROGRAM */
  
  section.app-download {
    background-image: url(/static/media/phoneApp-Background2.9c22cf09.png);
    /* background-color: #ffffff; */
    background-position: right top; 
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    height: 48.65vw;
    max-height: 700px;
  
    background-color: #181818 !important;
    /* outline: 10px solid #181818 !important; */
    outline-offset: -5px;
    /* border-top: solid #232323 10px; */
  }
  
  section.app-download div.content {
    display: flex;
    color: #d9ab73;
    /* opacity: 100%; */
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    margin-left: 15%;
    width: 30vw;
    max-width: 535px;
    z-index: 10;
  }
  
  section.app-download a.get-app-link {
    background-color: rgba(201, 76, 76, 0);
    border-radius: 3px;
    line-height: 3.2vw;
    color: #d9ab73;
    font-family: "Montserrat", sans-serif;
    font-size: 1vw;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    max-width: 14vw;
    max-height: 3.45vw;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
    padding: 0 20px 0 20px;
    border: 2px solid #d9ab73;
    border-radius: 12px;
  }
  
  section.app-download a.get-app-link:hover {
    background-color: #d9ab73 !important;
    color: #232323 !important;
    cursor: pointer !important;
    text-decoration: none;
  }
  
  section.app-download div.content h2 {
    font-family: "Crimson Text Pro", serif;
    font-size: 2.4vw;
    font-weight: 900;
    line-height: 3.95vw;
    /* margin: 0 0 2px; */
    margin-bottom: 10px;
    max-width: 525px;
    text-align: center;
    color: #d9ab73;
  }
  
  section.app-download div.content p {
    font-family: "Montserrat", sans-serif;
    font-size: 1vw;
    line-height: 1.75vw;
    margin: 0 0 3vw;
    color: #f2e6d5;
    text-align: center;
  }
  
  section.app-download div.content h2.desktop-only {
    display: block;
  }
  
  section.app-download div.content h2.non-desktop-only {
    display: none;
  }
  
  section.app-download .mobile-only {
    display: none;
  }
  
  section.app-download div.img-container {
    width: 50%;
    z-index: 10;
    position: relative;
    pointer-events: none;
  }
  
  section.app-download div.img-container img {
    position: absolute;
    bottom: -20%;
    right: 10%;
    width: 47.5vw;
    height: 47vw;
    max-height: 700px;
    pointer-events: none;
    vertical-align: middle;
    border: 0;
    
  }
  
  svg:not(:root) {
  overflow: hidden;
  }
  
  section.app-download svg.rotated {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    stroke-width: 0.1px;
    /* opacity: 97%; */
  }
  
  @media screen and (min-width: 576px) {
  
    #showOnMobile {
        display: none;
    } 
  
  }
  
  @media screen and (max-width: 576px) {
  
    #hideOnMobile {
        display: none;
    }
  
    #showOnMobile {
        display: block;
    } 
  
    section.ShopNow {
        /* height: 70vh !important; */
        width: 100%;
        text-align: center;
        flex-direction: column;
        padding-top: 40px;
        max-height: none;
        padding-bottom: 40px;
    }
  
    .ShopNow .col {
        width: 100%;
        padding-top: 0px;
        /* margin-bottom: 10%; */
    }
    .ShopNow .image-col {
        width: 100%;
        min-height: 30vh;
    }
  
    .ShopNow img {
        max-height: auto;
        max-width: 100%;
        margin: 0 auto;
        height: 20vh;
    }
    .ShopNow .top-image {
        /* margin-top: 12vh; */
    }
    .ShopNow .bottom-image {
        padding-bottom: 12vh;
    }
  
    .ShopNow article {
        top: 0;
        padding: 0;
        max-width: none !important;
        align-items: center;
    }
  
    .ShopNow article h2 {
        font-size: 8.5vw;
        line-height: 14.5vw;
    }
  
    .ShopNow article p {
        text-align: center;
        max-width: 75vw;
        font-size: 4.5vw;
        line-height: 6vw;
    }
  
    .ShopNow article a {
        min-width: 20vw;
        max-width: 100vw;
        max-height: none;
        line-height: 11vw;
        font-size: 3vw;
        padding: 0 20px;
  
    }
  
    section.Categories {
        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 0;
    }
    
    section.Categories article {
        position: relative;
    }
    
    section.Categories .mobile-only {
        display: block;
    }
    
    section.Categories article input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        z-index: 10;
        max-height: 62px;
    }
    
    section.Categories article input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        z-index: 10;
        max-height: 62px;
    }
    section.Categories article header i {
        position: absolute;
        right: 28px;
        top: 24px;
    }
    
    section.Categories article header {
        background: #f7f7f7;
        border-bottom: solid 2px #d8d8d8;
        font-size: 24px;
        line-height: 34px;
        padding: 13px 28px;
        text-align: left;
        text-transform: uppercase;
        cursor: pointer;
    }
    
    section.Categories article header i {
        position: absolute;
        right: 28px;
        top: 24px;
    }
   
    section.Categories article ul {
        display: none;
        background-color: #ffffff;
    }
  
    section.Categories article ul li {
        padding: 18px 0 0 30px;
    }
  
    section.Categories article ul li a {
        text-align: left;
        width: 100%;
        display: block;
        font-size: 4.5vw;
        line-height: 6vw;
    }
  
    section.Categories article input:checked ~ ul {
        display: block;
    }
  
    section.Categories article ul li a {
        text-align: left;
        width: 100%;
        display: block;
        font-size: 4.5vw;
        line-height: 6vw;
    }
  
    section.Categories article ul .all-link {
        text-align: left;
        padding: 0 0 15px 0px;
        justify-content: flex-start;
    }
  
    section.Categories i.right-arrow {
        width: 3vw;
        height: 4.5vw;
    }
  
    section.app-download {
        height: 35vh !important;
        max-height: none;
        padding-bottom: 10vh;
    }
  
    section.app-download div.content {
        width: auto;
        margin: 40px auto 15px;
    }
  
    section.app-download a.get-app-link {
        min-width: 20vw;
        max-width: 100vw;
        max-height: none;
        line-height: 11vw;
        font-size: 3vw;
        padding: 0 20px;
    }
  
    section.app-download div.content h2 {
        font-size: 6.5vw;
        line-height: 9vw;
    }
  
    section.app-download div.content h2.desktop-only {
        display: none;
    }
  
    section.app-download div.content h2.non-desktop-only {
        display: block;
        text-align: center;
    }
  
    section.app-download div.content p {
        margin: 0 auto 21px;
        max-width: 370px;
        text-align: center;
    }
  
    section.app-download div.content p {
        text-align: center;
        margin: 0 auto 21px;
        max-width: 90%;
        font-size: 4.5vw;
        line-height: 6vw;
    }
  
    section.app-download div.img-container {
        width: 100%;
        height: 100%;
    }
  
    section.app-download div.img-container img {
        left: 34vw;
    }
  
    section.app-download .non-mobile-only {
        display: none;
    }
  
    section.app-download .mobile-only {
        display: block;
    }
  
    section.app-download {
        background-image: url(/static/media/phoneApp-Background-mobile-skinnytall.2e74559c.png);
        /* background-color: #ffffff; */
        background-position: left; 
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        height: 48.65vw;
        max-height: 700px;
    }
   
  }
  
  
  
  /* MOBILE HEIGHT CONTROLS */
  
  @media screen and (max-width: 576px) {
  
    #desktopHeroView {
        display: none;
    }
    
    #mobileTabletContentHolder {
        display: block !important;
    }
    #mobileHeroView {
        display: block;
    }
    #tabletHeroOutline {
        display: none !important;
    }
  
    #mobileHeroOutline425 {
        display: block !important;
    }
    #mobileHeroOutline375 {
        display: none !important;
    }
    #mobileHeroOutline320 {
        display: none !important;
    }
  
  
    section.hero {
        /* padding-top: 25px; */
        /* height: 100vh !important; */
        
    }
  
    
  
    .displayLogo {
        padding-top: 30px;
    }
  
    .displayImage {
        width: 50% !important;
        
        margin-top: 2vw;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
  
    .displayButtonContainer {
        margin-top: 20px;
        margin-bottom: 80px;
    }
  
    .storeFinderButton {
        margin-top: 2vh !important;
        margin-bottom: 6vh !important;
    
        background-color: rgba(201, 76, 76, 0);
        border-radius: 3px;
        line-height: 6vw !important;
        color: #d9ab73;
  
        font-family: "Montserrat", sans-serif;
        font-size: 4vw !important;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
  
        max-width: 100vw;
        max-height: 100vw;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        
        /* margin-top: 20px !important;
        margin-bottom: 20px !important; */
        padding: 15px 30px 15px 30px !important;
        border: 1px solid #d9ab73 !important;
        border-radius: 12px;
        text-decoration: none;
    
    }
  
    
  
    section.QuoteBanner .non-mobile-only {
        display: none ;
    }
  
    /* section.QuoteBanner .mobile-only {
        display: initial;
    } */
  
    section.QuoteBanner {
        height: 100% !important;
        background-image: none !important;
    }
  
    section.QuoteBanner.left {
        /* flex-direction: column;
        justify-content: flex-end; */
        /* padding-bottom: 10vw; */
        /* height: 70vh; */
    }
  
    section.QuoteBanner article { 
        /* max-width: 95%; */
        right: 0 !important;
        align-items: center;
        margin: auto;
        text-align: center;
    }
  
    section.QuoteBanner article h2 {
        font-size: 7vw;
        line-height: 14.5vw;
        max-width: none !important;
    }
  
    .QuoteBanner article p {
        text-align: center;
        /* max-width: 75vw; */
        font-size: 4.5vw;
        line-height: 6vw;
    }
  
    section.QuoteBanner article a {
        margin-top: 20px;
        min-width: 20vw;
        max-width: 100vw;
        max-height: none;
        line-height: 11vw;
        font-size: 2vw;
        padding: 0 20px;
    }
  
    section.ShopNow {
        /* height: 112vh !important; */
    }
  
    section.threeMainProducts {
        position: relative;
        background-color: #181818;
    
        padding-top: 10px;
        padding-bottom: 0px;
  
    }
  
    .threeProductCategoryHeadline {
      font-size: 8vw;
      color: rgb(217,171,115);
      padding-bottom: 6vw;
    }
  
    section.ShopNow article p {
        margin: 0 0 10vw;
    }
    
  
    section.app-download {
        height: 70vh !important;
        background-image: url(/static/media/phoneApp-Background-mobile-skinnytall.2e74559c.png);
        /* background-color: #f2e6d5; */
        background-position: 0px 0px; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }
    
    section.app-download div.content {
        padding: 10px 20px 10px 20px;
        margin-top: 80px;
        justify-content: unset;
    }
  
  }
* {
  box-sizing: border-box;
}
*::before, *::after {
  box-sizing: border-box;
}

.aboutYumaBorder {
    position: relative;
    /* z-index: 100; */
    padding: 0 !important;

}


.aboutYumaBorder::before {
    content: '';
    position: absolute;
    border: 1px solid #d9ab73;
    left: 0;
    right: 0;
    transform: scale(0.97,0.94);
    
    width: 100%;
    height: 100%;
    pointer-events: none;
    
}

.aboutYumaBorder::after {
    content: '';
    position: absolute;
    border: 1px solid #d9ab73;
    left: 0;
    top: 0;
    right: 0;
    transform:scale(0.95,0.98);

    width: 100%;
    height: 100%;
    pointer-events: none;
}

.about {
    position: relative;
    background-color: rgb(24,24,24);
    margin-left: 10px;
    margin-right: 10px;
}

.aboutHeader {
    /* background-image: url("https://yuma-way.local/wp-content/uploads/2021/04/aboutHeader.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aboutHeader .centeredImageContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  
}

.aboutHeader .centeredImageContainer img {
    width: 100%;
    /* opacity: 90%; */
}

.aboutPeople {
    width: 100%;
    position: relative;
}

.textContainer {
    position: relative;
    margin-left: 20vw;
    margin-right: 20vw;
    text-align: center;

    padding-top: 5vw;
    padding-bottom: 6vw;

    margin-top: 1vw;
    margin-bottom: 1vw;
}

.textContainer h1 {
    font-family: 'Crimson Text Pro';
    color: #d9ab73;
    font-size: 2em;
}

.textContainer p {
    font-family: 'Montserrat', sans-serif;
    color: #f2e6d5;
    font-size: 1em;
}

.textContainer a {
    color: #d9ab73;
}

.aboutImageContainer {
    position: relative;
    width: 100%;
}

.aboutImageContainer img {
    width: 100%;
    overflow:hidden;
    max-height: 500px;
}

@media screen and (max-width: 425px) {

    .textContainer {
        padding-top: 8vw;
        padding-bottom: 8vw;
    }
    .textContainer h1 {
        font-size: 1.5em;
    }

    .textContainer p {
        font-size: 0.7em;
    }

}



.termsOfUseContainer {
  overflow-x: hidden;    
  width: 100%;
    background: #181818;
    z-index: 1;
}

.termsOfUseLogoContainer {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    width: 15em;
}

.termsLogo {
  position: relative;
  width: 100%;
  margin-top: 3vw;
}

.termsOfUseTextContainer {
  margin-top: 2vw;
  margin-left: 20vw;
  margin-right: 20vw;
}

.termsOfUseContainer h1 {
  
  color: #d9ab73;
  font-family: "Crimson Text Pro", serif;
  padding: 1vw;
  font-size: 48px;

}

.termsOfUseContainer h3 {
  color: #d9ab73;
  font-family: "Crimson Text Pro", serif;
  padding: 1vw;
  font-size: 24px;
  margin-top: 2vw;
  /* margin-bottom: 2vw; */
}

.termsOfUseContainer  p {
  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 1vw;
  font-size: 16px;
}

.whiteText {
  color: whitesmoke;
  text-decoration: none;;
}


.yumaBorder {
    position: relative;
    /* z-index: 100; */
    padding: 0 !important;
}
  
  
.yumaBorder::before {
  content: '';
  position: absolute;
  border: 1px solid #d9ab73;
  left: 0;
  right: 0;
  transform: scale(0.97,0.94);
  width: 100%;
  height: 100%;
  /* pointer-events: none; */
  
}

.yumaBorder::after {
  content: '';
  position: absolute;
  border: 1px solid #d9ab73;
  left: 0;
  top: 0;
  right: 0;
  transform:scale(0.95,0.98);
  width: 100%;
  height: 100%;
  /* pointer-events: none; */
}

@media screen and (max-width: 768px) {

  .termsOfUseTextContainer {
    margin-left: 10vw;
    margin-right: 10vw;
  }

  .termsOfUseContainer h1 {
    font-size: 40px;
  }

  .termsOfUseContainer h3 {
    font-size: 20px;
  }

}

@media screen and (max-width: 768px) {
  .termsOfUseContainer h1 {
    margin-top: 5vw;
    font-size: 32px;
  }

}

@media screen and (max-width: 425px) {
  .termsOfUseContainer h1 {
    margin-top: 5vw;
    margin-bottom: 5vw;
  }

}

.dealContainer {
    background-color: #181818 !important;
    max-width: 100vw !important;
    margin-left: 5px;
    margin-right: 5px;

}

/* State Selector at Top of Page */

.stateSelector {

    position:relative;
    justify-self: center;
    text-align: center;
    align-content: center;
    
    margin-top: 10px;
    margin-bottom: 10px;
    
    z-index: 99;

    /* To Reduce the Hover Effect */
    margin-left: auto;
    margin-right: auto;

    width: -webkit-max-content;

    width: max-content;
}
  
.stateSelector .currentState {
  font-family: "Crimson Text",serif;

  cursor: pointer;
  width: -webkit-max-content;
  width: max-content;
  margin: auto;
  position: relative;
  text-align: center;

  font-size: 30px;
  
}

.currentState {
  position: absolute;
  color: #d9ab73;
}

.currentState::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -16px;
  border: 5px solid transparent;
  border-top: 5px solid #d9ab73;
  text-align: center;
}

.stateSelector:hover .selectableStates {
  cursor: pointer;
  position:absolute;

  display:block;
  
  z-index:99;
}

.selectableStates {
  display: none;
  position:absolute;

  font-family: "Crimson Text",serif;
  color: #d9ab73;
  background-color: rgb(24,24,24);
  
  left: 50%;
  transform: translate(-50%);
  
  border-radius: 12px;
  width: -webkit-max-content;
  width: max-content;

  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 3px;

  z-index:99;
}

.selectableStates h4 {
  cursor: pointer;

  font-size: 29px;
  color: #d9ab73 !important;
  text-decoration: none !important;
  padding: 5px;

  z-index:99;
}

.selectableStates:hover {
  background-color:#d9ab73 !important;
}

.selectableStates:hover h4 {  
  color: #181818 !important;
}

.selectableStates a {
  text-decoration: none !important;
  border-radius: 12px;
}


/* ------------------------------------ */
/* ---------- HERO CONTENT ------------ */
/* ------------------------------------ */

/* Carousel */

#dealsTopPageSection {
  /* max-height: 0vh; */
  padding-bottom: 10px;
}

/* @media only screen and (min-width: 426px) {
  #dealsTopPageSection .col-9 {
    padding-right: 10px !important;
  }

  #dealsTopPageSection .col-3 {
    padding-left: 10px !important;
  }
} */

.carouselContainer {
  position: relative;
  border: 1px solid #d9ab73;
  margin-bottom: 10px;
  max-width: 100vw;
}

.carouselImage {
  width: 100%;
  object-fit: contain;
}


.carousel-indicators {
    padding-bottom: 5vw;
}

/* Logo/Button Overlay */

.heroButtonBackground {
    /* pointer-events: none !important; */
    object-fit: contain;
    position: absolute; 
    background-color: rgb(0,0,0);
    opacity: 0.8;
    z-index: 1;
    box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2) !important;
  
    width: 100%;
    height: 100%;
  
}

.buttonLogoContainer {
    display: flex;
    position:relative;
    text-align: center;
    /* left: 10%; */
    z-index: 50;
  
    
    height: 100%;
    width: 100%;
}

.buttonLogoContainer img {
  cursor: pointer;
  width: 100%;
  position: absolute;
  top: 5vw;
  padding-bottom: 20px !important;
  left: 50%;
  transform: translate(-50%);
  z-index: 90;

  max-width: none !important;
}

.heroDealsLogo {
    cursor: pointer;
    width: 100%;
    position: absolute;
    top: 5vw;
    padding-bottom: 20px !important;
    left: 50%;
    transform: translate(-50%);
    z-index: 90;
}
  
.heroDealsLogo img {
    max-width: none !important;
}

/* USE THIS */

.newDealButton {
    cursor: pointer;
    position: relative;
    top: 50%;
    margin: 0 auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: rgba(201, 76, 76, 0);
    color: #d9ab73;
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
    padding: 5% 10%;
    border: 2px
    solid #d9ab73;
    border-radius: 12px;
    z-index: 10;
}

  
/* .newDealButton {
    cursor: pointer;
    position: absolute;
    
    top: 18vw;
    left: 50%;
  
    background-color: rgba(201, 76, 76, 0);
    
    color: #d9ab73;
    font-family: "Montserrat", sans-serif;
    font-size: 2vw;
    font-weight:900;
    text-transform: uppercase;
    text-decoration: none;
    text-align: center;
  
    padding: 20px 50px 20px 50px;
    line-height: 3.2vw;
  
    border: 2px solid #d9ab73;
    border-radius: 12px;
    
    -ms-transform: translate(-50%);
    transform: translate(-50%);
  
    z-index:10;
} */
  
.newDealButton:hover {
    cursor: pointer;
    background-color: #d9ab73;
    text-decoration: none;
    color: #232323;
    transition: all 0.2s ease-in-out;
}


/* Right Column */

.rightTopColumn {
    position: relative;
    width: 100%;
    margin: none !important;
    padding: none !important; 
}

.topRightCouponContainer {
  position: relative;
  margin-top: 0;
  max-height: 100vh;
  overflow-y: hidden;
  /* width: 100%; */
  /* margin-bottom: 2vw; */
  border: 1px solid #d9ab73;
}
  
.topRightCouponContainer img {
  object-fit:contain;
  width: 100%;
}

/* --------------------------- */
/* //////// CSS GRID ////////// */
/* --------------------------- */

.cssGridTemplate1 {
    display: grid;
    grid-template-columns: repeat(auto-fit, calc(100%/2));
}

.cssGridTemplate2 {
  display: grid;
  grid-template-columns: repeat(auto-fit, calc(100%/3));
}

.cssGridTemplate3 {
  display: grid;
  grid-template-columns: repeat(auto-fit, calc(100%/4));
}

.gridItem {
    margin-top: 10px;
    margin-bottom: 10px;

    margin-left: 10px;
    margin-right: 10px;

    left:0;
    /* justify-content: center; */
    /* max-width: 100%; */

    position: relative;
    border: 1px solid #d9ab73;
}

.gridItem img {
  object-fit: cover;
  width: 100%;
}

.donateUkraineImg{
  width: 50%;
}


@media only screen and (min-width: 426px) {


    #gridI1 {
      grid-column: span 1;
    }
    
    #gridId2 {
      grid-column: span 2;
      grid-row: span 2;
    }
    
    #gridId3 {
      grid-column: span 1;
      grid-row: span 2;
    }

}

@media only screen and (max-width: 425px) {

    #grid-container {
        grid-template-columns: repeat(1, 1fr);
        max-width: 100vw;
        margin-left: 5px;
        /* padding-right: 5px; */
    }

    .cssGridTemplate1 {
        display: grid;
        grid-template-columns: repeat(auto-fit, calc(100%));
    }
    
    .cssGridTemplate2 {
      display: grid;
      grid-template-columns: repeat(auto-fit, calc(100%));
    }
    
    .cssGridTemplate3 {
      display: grid;
      grid-template-columns: repeat(auto-fit, calc(100%));
    }

    #gridId1 {
      grid-column: span 1;
      grid-row: span 1;
    }
    
    #gridId2 {
      grid-column: span 2;
      grid-row: span 2;
    }
    
    #gridId3 {
      grid-column: span 1;
      grid-row: span 2;
    }
}



@media only screen and (max-width: 1024px) {
    /* .newDealButton {
      font-size: 2vw;
      padding: 20px 50px 20px 50px;
      font-weight: 900;
    } */
  }
  
@media only screen and (max-width: 425px) {
  
    .newDealButton {
      
      padding: 5% 10%;
      font-size: 15px;
    }
  
  }
  
  @media only screen and (min-width: 425px) {
    #desktopAndTabletView {
      display: block;
    }
    #mobileView {
      display: none;
    }

    #dealsTopPageSection {
      margin-left: 10px;
      margin-right: 10px;
    }

  }
  
  @media only screen and (max-width: 425px) {
  
    #dealsTopPageSection {
      
      padding-top: 10px;
      padding-bottom: 10px;
    }
  
    #desktopAndTabletView {
      display: none;
    }
    #mobileView {
      display: block;
      /* padding: 0px; */
    }
  
    .heroDealsLogo {
      width: 140%;
      top: 2vw;
    }
  
    /* .newDealButton {
      top: 22vw;
      left: 50%;
  
      line-height: 3.2vw;
      
      font-family: "Montserrat", sans-serif;
      font-size: 4vw;
      font-weight: 900;
      
      max-width: none;
      max-height: none;
  
      padding: 10px 15px 10px 15px;
  
      border-radius: 8px;
      border: 2px solid #d9ab73;
    } */
  
    #secondRow {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
  
      margin-top: 0;
      margin-bottom: -10px;
    }

    
  
  }
  

@media (min-width: 991px) {
  .search {
    color: #d9ab73;
    position: -webkit-sticky;
    position: sticky;
    text-align: center;
    /* margin-bottom: 5vh; */
    z-index: 20;
    top: 0vh;
    padding-top: 1px;
    margin-bottom: 5vh;

    background-color: rgb(24,24,24, 0.9);
    box-shadow: 0px -12px 30px 20px rgb(24,24,24);
    
  }

  .storeFinderInfo {
    height: calc(100vh - 70px - 55px);
    overflow-y:auto;
    overflow-x:hidden;
  }

  .storePageHeight {
    height: calc(100vh - 70px - 55px);
    overflow:hidden;
  }

}


/* .search {
  color: #d9ab73;
  text-align: center;
  margin-bottom: 5vh;
} */



@media (max-width: 991px) {

  /* .storeFinderInfo  {
    height: calc(100vh - 70px - 55px);
    overflow-y: scroll;
  }

  .stickyMap {
    position: relative !important;
  } */
  .mapCol {
    /* position: fixed; */
    right:0;
  }
  .search {
    display: none;
  }
  .storeCards {
    margin-top: 20px;
    /* z-index: 1000; */
  }
  .mobileSearch {
    display: block !important;
    z-index: 1;
  }
  .desktopMap {
    display: none;
  }
  .mobileMap {
    display: block !important;
  }

  
}

.mobileMap {
  display: none;
}
.mobileSearch {
  color: #d9ab73;
  text-align: center;
  margin-bottom: 5vh;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  display: none;
}
.search h1 {
  margin-bottom: 1vh;
}

.mapCol {
  padding: 0px !important;
}

.selectedStoreInfo {
  padding: 0px !important;
}

.stickyMap {
  /* position: -webkit-sticky;
  position: sticky; */
  top: 0px;
  padding: 0px !important;
}

.stickyMap > div > div {
  height: calc(100vh - 65px);
  position: relative;
  overflow: hidden;
  /* pointer-events: none; */
}

/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@1,200&display=swap"); */

col-6 {
  flex: 1 0 50%;
  max-width: 50%;
}

.yumaBorder {
  position: relative;
  /* z-index: 100; */
  padding: 0 !important;
}


.yumaBorder::before {
  content: '';
  position: absolute;
  border: 1px solid #d9ab73;
  left: 0;
  right: 0;
  transform: scale(0.97,0.94);
  /* --webkit-transform: scale(97%,94%); */
  width: 100%;
  /* z-index: 100; */
  height: 100%;
  pointer-events: none;
  
}

.yumaBorder::after {
  content: '';
  position: absolute;
  border: 1px solid #d9ab73;
  left: 0;
  top: 0;
  right: 0;
  transform:scale(0.95,0.98);
  /* --webkit-transform: scale(97%,94%); */
  width: 100%;
  /* z-index: 100; */
  height: 100%;
  pointer-events: none;
}

button {
  cursor: pointer !important;
  z-index: 1;
}

#store {
  display: flex;
  width: auto;
  /* cursor: pointer; */
  text-align: left;
  /* pointer-events: none; */
  flex-shrink: 0;
  max-width: 100%;
  align-items: center;
  color: #d9ab73;
  height: 11.5em;
  position: relative;
  background: rgb(0, 0, 0, 0);
  font-family: "Linux Libertine";
}

#store img {
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  max-height: 100%;
  margin-top: -22px;
  padding-bottom: 10px;
}

#store h4 {
  font-size: 1em;
  font-weight: bolder;
  margin-bottom: 6px;
  text-decoration: none;
  text-align: center;
  font-weight: 100;
  line-height: 0.9em;
}

#store svg.rotated {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  stroke-width: 0.2px;
  /* z-index: 1; */
  /* pointer-events: none; */
}

#store svg.line {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  stroke-width: 0.3px;
  /* z-index: -1; */
}

#store p {
  text-align: center;
}

#storeInfo {
  margin: 0;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  width: 90%;
  left: 2%;
}

.separatorBox {
  height: 10vw;
}

#buttonWrapper {
  background: transparent;
  padding: 0px;
  border: none;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  max-width: 600px;
}



.reactButtonContainer {
  position: relative;
  text-align: center;
  display:block;
  width: 100%;

}

.storeButtonReact {
  cursor: pointer !important;
  position: relative;

  line-height: 2vw;
  padding: 0px 1vw 0px 1vw;
  
  text-align: center;
  /* margin-top: -10px;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto; */

  border: 1px solid transparent;
  font-weight: 800;

  background: rgba(201, 76, 76, 0);
  color: #d9ab73;
  
  font-family: "Montserrat", sans-serif;
  font-size: 0.9vw;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  /* max-width: 14vw;
  max-height: 3.45vw; */

  transition: all 0.2s ease-in-out;
  
  border: 1px solid #d9ab73;
  border-radius: 8px;
  text-decoration: none;
  pointer-events: all;
  
}

.storeButtonReact:hover {
  cursor: pointer !important;
  background: #d9ab73 !important;
  color: #181818 !important;
  pointer-events: all;
}

.storeButtonReact:focus {
  outline: none;
}


#svgSeparator {
  width: 100%;
  height: 50px;
  margin-top: 1vh;
  margin-bottom: 1vh;
}

.lineBox {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 100%;
  stroke-width: 0.5;
  /* z-index: 10; */
}

#logoColumn {
  /* padding-left: 3%; */
  /* left: 2%; */
}

#logoColumn img {
  width: 65%;
  padding-top: 1em;
  padding-bottom: 1em;
}

#distanceToStore {
  -webkit-text-decoration-color: #d9ab73;
          text-decoration-color: #d9ab73;
  font-size: 1.5em !important;
  font-family: "Crimson Pro", serif;
  font-weight: 300;
  padding-bottom: 5px;
}

#storeSearchInfo {
  font-family: "Montserrat", sans-serif;
  -webkit-text-decoration-color: #d9ab73;
          text-decoration-color: #d9ab73;
}

#storeContainer {
  background: transparent;
}

@media (max-width: 1024px) {
  
  .storeButtonReact {
    border-radius: 6px;
  }

  .storeButtonReact {
    font-size: 2vw !important;
    /* top: -20px !important; */
    padding: 1vw 2vw 1vw 2vw !important;
  }
  
}

@media (max-width: 500px) {
  .storeButtonReact {
    font-size: 2.5vw !important;
    top: 10px !important;
    padding: 2vw 4vw 2vw 4vw !important;
  }
}

@media (max-width: 500px) {

  #logoColumn img {
    width: 85%;
    padding-top: 1em;
    padding-bottom: 1em;
  }

}


/* @media (max-width: 500px) {
  #logoColumn img {
    width: 80%;
    padding-top: none;
    padding-bottom: none;
  }

} */
.search-bar-container {
  width: 90%;
  max-width: 600px;
  margin: 40px auto 0;
  z-index: 1;
}

.search-input-container {
    position: relative;
    border: 1px solid rgb(217,170,117);
    box-shadow: 0px 0px 10px 3px black;
 }
 
.search-input#menuSearch {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 8%);
   display: block;
   width: 100%;
   padding: 16px 16px 16px 36px;
   font-size: 16px;
   font-family: "Montserrat", sans-serif;
   border-radius: 2px;
   outline: none;
   background: #191919;
   border-color: #d9aa75;
   color: #f2e6d5;
  
  /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 8%);
  display: block;
  width: 100%;
  padding: 16px 16px 4px 36px;
  font-size: 16px;
  border-radius: 2px;
  outline: none;
  background: rgb(242, 230, 213, 0.53);
  color: white; */
}
.search-input#menuSearch::placeholder {
  color: #f2e6d5;
  border-bottom-color: rgb(217,170,117);
}
.search-input-container div div {
  background-color: #191919 !important;
  color: #f2e6d5 !important;
  border-bottom-color: rgba(217,170,117,.5) !important;
  
}
.search-input-container div:not(.icon) {
  border-bottom: 1px solid rgba(217,170,117,.5);

}


::placeholder {
  color: rgb(242, 230, 213, 0.8);
  font-family: "Montserrat", sans-serif;
  border: 0;
  outline: 0;
  border-bottom: 1px solid rgb(242, 230, 213);
}

button {
  border-color: #d9ab73;
  background: #191919;
  border-radius: 10px;
  color: rgb(217,170,117);
  font-family: "Montserrat",sans-serif;
  margin: auto;
  font-weight: bolder;
  font-size: 1.4vw;
}

.icon {
  position: absolute;
  right: 20px;
  top: 13px;
  color: white;

}

.geoLocationButton, .icon {
  position: absolute;
  top: 13px;
  color: rgb(217,170,117);
}


.geoLocationButton {
  position: absolute;
  left: 10px;
  top: 13px;
  width: 20px;
  background: transparent;
  border: transparent;
  padding: 0px;
}

@media (max-width: 991px) {
  .search-input#menuSearch {
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 8%);
    display: block;
    width: 100%;
    padding: 16px 16px 4px 36px;
    font-size: 16px;
    border-radius: 2px;
    outline: none;
    background: #171717;
    color: rgb(217,170,117);

    /* box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%), 0 0 0 1px rgb(0 0 0 / 8%);
    display: block;
    width: 100%;
    padding: 16px 16px 4px 36px;
    font-size: 16px;
    border-radius: 2px;
    outline: none;
    background: rgb(23, 23, 23, 1);
    color: rgb(255, 255, 255); */
  }
  ::placeholder {
    color: rgb(242, 230, 213, 0.8);
    font-family: "Montserrat", sans-serif;
    border: 0;
    outline: 0;
    border-bottom: 1px solid rgb(242, 230, 213);
  }

  .icon {
    color: rgb(255, 255, 255, 0.7);
  }

  .geoLocationButton {
    color: rgb(255, 255, 255, 0.7);
  }

  .geoLocationButton, .icon {
    color: rgb(217,170,117);
 }
 
}

.storeImage {
  width: 100%;
}
.storeLogo {
  width: 80%;
  padding-top: 10px;
  margin-bottom: 15px;
}
.infoWindow {
  max-width: 250px;
  text-align: center;
  font-family: "linux liberte";
  background-color: rgb(0, 0, 0, 0.9);
  color: #f2e6d5;
}

.mapStoreLink {
  font-family: "Crimson Pro,serif";
  color: #f2e6d5;
}

.mapStoreLink:hover {
  font-family: "Crimson Pro,serif";
  color: #f2e6d5;
  text-decoration: none;
}

#address {
  font-size: 1.4em;
  line-height: 1.5;
  margin-bottom: 0px;
}

.phone {
  padding-bottom: 15px;
  margin: 0px;
}

.gm-style-iw {
  width: 250px !important;
  top: 0 !important;
  left: 0 !important;
  background-color: rgb(0, 0, 0, 0.9);
  box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
  padding: 0px !important;
  overflow: hidden !important;
}

.gm-style-iw-d {
  overflow: auto !important;
}


.containerPadding {
  padding: 0px;
  background-color: transparent !important;
}

.stickyContainer {
  position: -webkit-sticky;
  position: sticky;
  top: 0px !important;
  padding-top: 20px;
  background-color: rgb(23, 23, 23) !important;
  z-index:  90;
}

.logo {
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  height: 100% !important;
  max-height: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  stroke-width: 0;
}

.backDiv {
  position: relative;
}

.backButton {
  position: absolute;
  width: 20px;
  top: 40%;
  left: 5vw !important;
  background: transparent;
  border: transparent;
  color: #d9ab73;
  padding: 0px !important;
  /* stroke-width: 0 !important;
  outline: 0 !important; */
}

.backButton:focus {
  outline: none;
}

.backArrow {
  /* stroke-width: 0 !important;
  outline:0 !important; */
}

@media (max-width: 768px) {
  .backButton {
    left: 5vh;
  }
}

.buttonContainer {
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-bottom: 15px;
}

.button {
  border: 1px solid rgb(217,170,117,.5);
  background: #191919;
  border-radius: 10px;
  color: rgb(217,170,117);
  font-family: "Montserrat",sans-serif;
  font-size: 89%;
  margin: auto;
  min-width: auto !important;
  font-weight: bolder;
  outline: none;
}

.button:hover {
  cursor: pointer !important;
  background: #d9ab73;
  color: #181818;
}

.button:focus {
  outline: none;
}

.tri {
  padding: 20px 1vw 20px 1vw !important;
  min-width: 14vh;
  margin-right: 3px;
  margin-left: 3px;
  font-family: "Montserrat", sans-serif;
}

.duo {
  padding: 20px 2vw 20px 2vw !important;
  min-width: 18vh;
  margin-right: 3px;
  margin-left: 3px;
  outline: none;
}

/* CONTACT INFO */

.phoneAndAddress {
  color: #f2e6d5;
  margin: auto;
  text-align: Center;
  margin-top: 15px;
  width: 85%;
  margin-bottom: 15px;
  font-family: "Crimson Pro", serif;
}

.storeImage {
  width: 100%;
}

.innerPhoneAndAddress {
  border-top: 1px solid rgb(242, 230, 213, 0.3);
  border-bottom: 1px solid rgb(242, 230, 213, 0.3);
}

.phoneLink {
  color: inherit;
}
.phoneLink:hover {
  color: #d9ab73;
}
.hoverAddress {
  cursor: pointer;
}
.hoverAddress:hover {
  color: #d9ab73;
}

.phoneIcon {
  color: #d9ab73;
  margin-bottom: 10px;
  margin-top: 15px;
}

.locationIcon {
  color: #d9ab73;
  margin-top: 20px;
  margin-bottom: 10px;
}

.phoneNumber {
  margin-bottom: 15px;
}

/* SOCIAL MEDIA */

.socialMediaContainer {
  margin-top: 10px;
  margin-bottom: 20px;

}

.socialIcon {
  padding-left: 5px;
  padding-right: 5px;
  transition: all .2s ease-in-out; 
}

.socialIcon:hover {
  transform:scale(1.1);
}

/* STORE HOURS */

.hoursContainer {
  text-align: center;
  width: 85%;
  margin: auto;
  font-size: 1.5rem;
  margin-bottom: 25px;
}
.hoursContainer h4 {
  color: #d9ab73;
  font-size: 2.5rem;
  font-family: "Crimson Pro", serif;
}

.storeTimes {
  font-family: "Montserrat", sans-serif;
  color: #f2e6d5;
}

.storeDescription {
  width: 85%;
  color: #f2e6d5;
  text-align: center;
  margin: auto;
  margin-top: 25px;
  margin-bottom: 20px;
}
.storeDescription h4 {
  border-top: 1px solid rgb(242, 230, 213, 0.3);
  border-bottom: 1px solid rgb(242, 230, 213, 0.3);
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 2.5rem;
  font-family: "Crimson Pro", serif;
  color: #d9ab73;
}
.storeDescription p {
  width: 85%;
  margin-bottom: 20px;
  color: #f2e6d5;
  font-family: "Montserrat", sans-serif;
  /* font-style: italic; */
  text-align: left;
  font-size: 17px;
  font-weight: 400;
}


.hourRow {
  box-shadow: rgba(0, 0, 0, 0.75) 0px 0px 6px;
  margin-bottom: 15px;
  border-top: 1px solid rgb(242, 230, 213, 0.3);
  border-bottom: 1px solid rgb(242, 230, 213, 0.3);
  font-family: "Montserrat", sans-serif;
}

.day {
  text-transform: capitalize;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  max-width: 80%;
}
.hours {
  text-align: right;
  font-family: "Montserrat", sans-serif;
  max-width: 40%;
}

@media only screen and (max-width: 767px) {

  .hours {
    text-align: right;
    font-family: "Montserrat", sans-serif;
    max-width: none;
  }

}

.hourContainer {
  background-color: transparent !important;
}

@media only screen and (max-width: 767px) {

  .hours {
    text-align: right;
    font-family: "Montserrat", sans-serif;
    max-width: none;
  }

}

@media only screen and (max-width: 500px) {
  .hoursContainer {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 340px) {
  .hoursContainer {
    font-size: 0.8rem;
  }
}

@media only screen and (max-width: 320px) {
  .hoursContainer {
    font-size: 0.7rem;
  }
}
.menuBackButtonBar {
  position: absolute;
  justify-content: center;
  display: flex;
  padding: 15px;
  align-items: center;
  -webkit-box-pack: center;
  font-size: 14px;
  padding-right: 16px;
  padding-left: 16px;
  margin: 5px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-weight: bold;
  height: 40px;
  white-space: nowrap;
  background-color: rgb(182, 175, 144);
  border-radius: 5px;
  top: 60px;
  z-index: 99;
}

#navbar {
  position: -webkit-sticky;
  position: sticky;
  display: block;
  top: 0;
  background-color: rgb(24, 24, 24);
  width: 100%;
  z-index: 100;
  text-align: right;
  padding: 14px;
  box-shadow: 0 2px 8px -2px black;
}

.navbarImage {
  float: left;
}

.navbarImage img {
  float: left;
  width: 12em;
}

.navbarMainItems {
  /* float: right; */
  display: flex;
  justify-content: end;
}

#navbar a:hover {
  color: #d9ab73;
}

.mainNavItem {
  color: #f2e6d5;

  text-decoration: none;

  font-size: 28px;
  font-family: "Crimson Text Pro", serif;
  padding: 14px;
}

.donateImg {
  width: 70px !important;
  transition: all ease-out 0.2s;
}
/* Dropdown */

.dropdown ul {
  list-style: none;
  font-size: 28px;
  font-family: "Crimson Text Pro", serif;
  padding: 14px;
}

.dropdown ul a {
  display: block;
  text-decoration: none;
}

.dropdown ul li {
  position: relative;
}

.dropdown ul ul {
  display: none;
  position: absolute;
  padding: 0;
}

.dropdown ul li:hover > ul {
  display: block;
}

.dropdown-item {
  background-color: #d9ab73;
  font-size: 1.2rem;
}

a {
  color: #f2e6d5;
}

@media screen and (max-width: 768px) {
  #navbar img {
    width: 10em;
  }
  .mainNavItem .dropdown ul {
    font-size: 22px;
  }
  .navbarMainItems {
    margin-top: 15px;
  }
}

@media screen and (max-width: 425px) {
  #navbar {
    padding: 10px;
  }
  #navbar img {
    width: 8em;
  }
  .mainNavItem .dropdown ul {
    font-size: 18px;
    padding: 10px;
  }
  .navbarMainItems {
    margin-top: 15px;
  }
}

@media screen and (max-width: 375px) {
  #navbar img {
    width: 7em;
  }
  .mainNavItem .dropdown ul {
    font-size: 16px;
  }
  .navbarMainItems {
    margin-top: 15px;
  }
}

.footerBackground {
    display: block;
    top:0;
    background: #181818;
    height: 55px;
    width: 100%;
    z-index: 100;
    text-align: right;
    padding: 14px;
}

.footerTMContainer {
    float: left;
    padding-left: 2vw;
}

.footerTM {
    color: white;
    font-size: 18px;
}

.footerTM:hover {
    color: #d9ab73;
    text-decoration: none;
}

.footerNav {
    float: right;
    margin-right: 1vw;
}

.footerNavItem {
    
    color: white;
    
    text-decoration: none;
    font-size: 18px;
    font-family: "Montserrat", sans-serif;
    
    padding-left: 25px;
 }

 .footerNavItem:hover {
    color: #d9ab73;
    text-decoration: none;
 }

 #spacer:hover {
     text-decoration: none;
 }

 @media screen and (max-width: 775px) {
    .footerTM {
        font-size: 10px;
    }

    .footerNavItem {
        font-size: 18px;
    }
 }
 
 @media screen and (max-width: 490px) {
    .footerTM {
        font-size: 10px;
    }

    .footerNavItem {
        font-size: 14px;
        padding-left: 15px;
    }
 }

 @media screen and (max-width: 425px) {
    .footerTM {
        font-size: 10px;
    }

    .footerNavItem {
        font-size: 12px;
        padding-left: 10px;
    }
 }

 @media screen and (max-width: 375px) {
    .footerTM {
        font-size: 8px;
    }

    .footerNavItem {
        font-size: 8px;
        padding-left: 5px;
    }
 }
#ageGateModalContainer {
  padding-top: 30px;
  padding-bottom: 30px;
  

  background-color: #181818;
  border-radius: 20px;
  display: fixed;

  position: fixed;
  /* bottom: -150vh; */
  transition: all 150ms east-in-out;

  top:50%;
  left:50%;
  transform: translate(-50%,-50%);


  box-shadow: inset 0px 0px 5px rgba(0,0,0,0.16), 0px 0px 5px rgba(0,0,0,0.45);

  z-index:99;
}

.ageLogoContainer {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.ageGateModalContent {
  padding-top: 20px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 50px;
}

.ageGateHeadline {
  text-align: center;
  color: white;

  font-size: 18px;
  
  margin-top: 5px;
  margin-bottom: 15px;
  
}

.ageGateCopyPadding {
  margin-top: 0px;
  margin-bottom: 0px;
}

.ageGateButtons {
  text-align: center;

  /* padding-top: 10px;
  padding-bottom: 10px; */

}

.yesNoButton {
  font-size: 18px;
  margin-left: 20px;
  margin-right: 20px;

  padding-top: 10px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;

  text-align: center;
}

#yesAgeGate {
  float: right;
}

#noAgeGate {
  float: left;
}

#yesAgeGate:hover {
  color: #181818;
  background-color: #d9ab73;
}

#noAgeGate:hover {
  color: #181818;
  background-color: #d9ab73;
}

.termsAndConditions {
  text-align: center;
  color: white;

  font-size: 14px;

  margin-top: 20px;
  margin-bottom: 10px;
}

.termsAndConditions a {
  color: #d9ab73;
}

@media screen and (max-width: 900px){
  .ageLogoContainer img {
    width: 20em;
  }

  .ageGateHeadline {
    font-size: 16px;
  }

  .yesNoButton {
    margin-left: 0px;
    margin-right: 0px;
  }

  .termsAndConditions {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px){

  .ageGateModalContent {
    padding-left: 70px;
    padding-right: 70px;
  }
  
}

@media screen and (max-width: 500px){

  .ageGateModalContent {
    padding-left: 50px;
    padding-right: 50px;
  }
  
}

@media screen and (max-width: 425px){
  .ageLogoContainer img {
    width: 15em;
  }

  .ageGateModalContent {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

  .ageGateHeadline {
    font-size: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }

  .termsAndConditions {
    font-size: 12px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .yesNoButton {
    font-size: 18px;
    margin-left: 0px;
    margin-right: 0px;
  
    padding-top: 5px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 5px;
  
    text-align: center;
  }
  
}
