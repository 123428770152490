@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro&display=swap");
* {
  box-sizing: border-box;
}
*::before, *::after {
  box-sizing: border-box;
}

.aboutYumaBorder {
    position: relative;
    /* z-index: 100; */
    padding: 0 !important;

}


.aboutYumaBorder::before {
    content: '';
    position: absolute;
    border: 1px solid #d9ab73;
    left: 0;
    right: 0;
    transform: scale(0.97,0.94);
    
    width: 100%;
    height: 100%;
    pointer-events: none;
    
}

.aboutYumaBorder::after {
    content: '';
    position: absolute;
    border: 1px solid #d9ab73;
    left: 0;
    top: 0;
    right: 0;
    transform:scale(0.95,0.98);

    width: 100%;
    height: 100%;
    pointer-events: none;
}

.about {
    position: relative;
    background-color: rgb(24,24,24);
    margin-left: 10px;
    margin-right: 10px;
}

.aboutHeader {
    /* background-image: url("https://yuma-way.local/wp-content/uploads/2021/04/aboutHeader.jpg"); */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.aboutHeader .centeredImageContainer {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  
}

.aboutHeader .centeredImageContainer img {
    width: 100%;
    /* opacity: 90%; */
}

.aboutPeople {
    width: 100%;
    position: relative;
}

.textContainer {
    position: relative;
    margin-left: 20vw;
    margin-right: 20vw;
    text-align: center;

    padding-top: 5vw;
    padding-bottom: 6vw;

    margin-top: 1vw;
    margin-bottom: 1vw;
}

.textContainer h1 {
    font-family: 'Crimson Text Pro';
    color: #d9ab73;
    font-size: 2em;
}

.textContainer p {
    font-family: 'Montserrat', sans-serif;
    color: #f2e6d5;
    font-size: 1em;
}

.textContainer a {
    color: #d9ab73;
}

.aboutImageContainer {
    position: relative;
    width: 100%;
}

.aboutImageContainer img {
    width: 100%;
    overflow:hidden;
    max-height: 500px;
}

@media screen and (max-width: 425px) {

    .textContainer {
        padding-top: 8vw;
        padding-bottom: 8vw;
    }
    .textContainer h1 {
        font-size: 1.5em;
    }

    .textContainer p {
        font-size: 0.7em;
    }

}