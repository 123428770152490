#navbar {
  position: sticky;
  display: block;
  top: 0;
  background-color: rgb(24, 24, 24);
  width: 100%;
  z-index: 100;
  text-align: right;
  padding: 14px;
  box-shadow: 0 2px 8px -2px black;
}

.navbarImage {
  float: left;
}

.navbarImage img {
  float: left;
  width: 12em;
}

.navbarMainItems {
  /* float: right; */
  display: flex;
  justify-content: end;
}

#navbar a:hover {
  color: #d9ab73;
}

.mainNavItem {
  color: #f2e6d5;

  text-decoration: none;

  font-size: 28px;
  font-family: "Crimson Text Pro", serif;
  padding: 14px;
}

.donateImg {
  width: 70px !important;
  transition: all ease-out 0.2s;
}
/* Dropdown */

.dropdown ul {
  list-style: none;
  font-size: 28px;
  font-family: "Crimson Text Pro", serif;
  padding: 14px;
}

.dropdown ul a {
  display: block;
  text-decoration: none;
}

.dropdown ul li {
  position: relative;
}

.dropdown ul ul {
  display: none;
  position: absolute;
  padding: 0;
}

.dropdown ul li:hover > ul {
  display: block;
}

.dropdown-item {
  background-color: #d9ab73;
  font-size: 1.2rem;
}

a {
  color: #f2e6d5;
}

@media screen and (max-width: 768px) {
  #navbar img {
    width: 10em;
  }
  .mainNavItem .dropdown ul {
    font-size: 22px;
  }
  .navbarMainItems {
    margin-top: 15px;
  }
}

@media screen and (max-width: 425px) {
  #navbar {
    padding: 10px;
  }
  #navbar img {
    width: 8em;
  }
  .mainNavItem .dropdown ul {
    font-size: 18px;
    padding: 10px;
  }
  .navbarMainItems {
    margin-top: 15px;
  }
}

@media screen and (max-width: 375px) {
  #navbar img {
    width: 7em;
  }
  .mainNavItem .dropdown ul {
    font-size: 16px;
  }
  .navbarMainItems {
    margin-top: 15px;
  }
}
