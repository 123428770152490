/* body {
  position: absolute;
} */

.holder {
  width: 100vw;
  /* height: calc(100vh - 70px); */
  overflow-x: hidden;
  overflow-y: auto;
  background: #181818;
  /* z-index: -1; */
}

#ageGateBackground {
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: none;
  background: rgb(0, 0, 0, 0.75);
  z-index: 99;
  transition: opacity 0.5s;
}

.darkBackground {
  background-color: #181818 !important;
}

.separate-containers .inside-article {
  background-color: white;
}

.footer-widgets .widget-title {
  margin-bottom: 10px;
}
.inside-site-info {
  font-size: 12px;
}

#page {
  padding: 0px;
}
