.menuBackButtonBar {
  position: absolute;
  justify-content: center;
  display: flex;
  padding: 15px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  font-size: 14px;
  padding-right: 16px;
  padding-left: 16px;
  margin: 5px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  font-weight: bold;
  height: 40px;
  white-space: nowrap;
  background-color: rgb(182, 175, 144);
  border-radius: 5px;
  top: 60px;
  z-index: 99;
}
