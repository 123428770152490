/* --------- */
/* HOME PAGE */
/* --------- */

.yumaBorder {
    position: relative;
    /* z-index: 100; */
    padding: 0 !important;
  }
  
  
  .yumaBorder::before {
    content: '';
    position: absolute;
    border: 1px solid #d9ab73;
    left: 0;
    right: 0;
    transform: scale(0.97,0.94);
    width: 100%;
    height: 100%;
    /* pointer-events: none; */
    
  }
  
  .yumaBorder::after {
    content: '';
    position: absolute;
    border: 1px solid #d9ab73;
    left: 0;
    top: 0;
    right: 0;
    transform:scale(0.95,0.98);
    width: 100%;
    height: 100%;
    /* pointer-events: none; */
  }
  
  /* INITIAL PAGE SETUP */
  #hideOnDesktop { 
    display: none;
  }
  
  #mobileHeroView {
    display: none;
  }
  
  #desktopHeroView {
    display: flex;
  }
  
  #desktopHeroOutline {
    display:flex;
  }
  
  #tabletHeroOutline {
    display: none;
  }
  /* #mobileTabletContentHolder {
    display: none;
  } */
  
  #mobileHeroOutline425 {
    display: none;
  }
  
  .darkBackground {
    /* padding-top: 100px; */
    background-color: #181818 !important;
  }
  
  /* HOME: HERO SECTION */
  
  section.hero {
    position: relative;
    height: 100%;
    /* width: 100vw; */
    
  }
  section.hero .heroCol {
    width: 100%;
    position: relative;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    z-index: 2;
    min-height: 40vh;
  
  }
  
  /* section.hero svg.rotated {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    stroke-width: 0.1px;
    z-index: 2;
  
  } */
  
  section.hero .storeFinderButton {
    background-color: rgba(201, 76, 76, 0);
    border-radius: 3px;
    line-height: 3.2vw;
    color: #d9ab73;
    font-family: "Montserrat", sans-serif;
    font-size: 1.14vw;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    max-width: 24vw;
    max-height: 3.45vw;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  
    margin-left: auto;
    margin-right: auto;
    margin-top: 4vh;
    
    padding: 0 20px 0 20px;
    border: 2px solid #d9ab73;
    border-radius: 12px;
    text-decoration: none;
  
    z-index: 999;
  }
  
  .storeFinderButton:hover {
    background-color: #d9ab73 !important;
    color: #232323 !important;
    cursor: pointer !important;
  }
  
  section.hero .logo {
    display: flex;
    position: relative;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    width: 100%;
  
    margin-left: 10vw;
    /* margin-right: auto; */
  
  }
  
  section.hero .heroCol #logoCol {
    position: relative;
    
  }
  
  section.hero .heroCol #imageCol {
    position: relative;
    margin-left: 5vw;
  }
  
  
  
  .displayLogoContainer {
    /* width: 100%; */
    position: relative;
    
  }
  
  .displayLogo {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    text-align: center;
  }
  
  .displayImageContainer {
    position: relative;
    width: 100%;
    text-align: center;
  }
  
  .displayImage {
    width: 50% !important;
    margin-top: 50px; 
    margin-left: 50px; 
  }
  
  .displayButtonContainer {
    position: relative;
    width: 100%;
    text-align: center;
  
    margin-top: 20px;
  }
  
  /* HOME: MASSIVE PRODUCT SECTION */
  
  .QuoteBanner {
    position: relative;
    margin-top: 15px;
    margin-bottom: 15px;
  
  }
  
  .QuoteBanner .mobile-only {
    display: none;
  }
  
  .QuoteBanner img {
    width: 125%;
    background-attachment: fixed;
  }
  
  .QuoteBanner article {
    right: 7vw;
    margin: 0;
    position: relative;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  
    justify-content: center;
    margin-left: auto;
    z-index: 10;
    max-width: 70%;
  }
  
  .QuoteBanner article h2 {
    font-family: "Crimson Text Pro", serif;
    font-size: 2.4vw;
    font-weight: 900;
    line-height: 3.95vw;
    margin: 0 0 5px;
    /* max-width: 525px; */
    text-align: center;
    color: #d9ab73;
  
  }
  
  .QuoteBanner article p {
    font-family: "Montserrat", sans-serif;
    font-size: 1vw;
    line-height: 1.75vw;
    /* margin: 0 0 3vw; */
    /* max-width: 450px; */
    color: #f2e6d5;
    text-align: center;
    /* margin-bottom: 20px; */
  }
  
  .QuoteBanner article a {
    background-color: rgba(201, 76, 76, 0);
    border-radius: 3px;
    line-height: 3.2vw;
    color: #d9ab73;
    font-family: "Montserrat", sans-serif;
    font-size: 1.14vw;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    /* max-width: 14vw;
    max-height: 3.45vw; */
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
    padding: 0 20px 0 20px;
    border: 2px solid #d9ab73;
    border-radius: 12px;
  }
  
  .QuoteBanner article a:hover {
    background-color: #d9ab73;
    color: #232323;
    cursor: pointer;
  }
  
  .QuoteBanner .article_text {
    position: relative;
    /* right: 0%; */
    /* top: 30%; */
  }
  
  
  
  /* HOME: CALL-TO-SHOP SECTION */
  
  .ShopNow {
    background-color: #181818;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: scale-down; /* Resize the background image to cover the entire container */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position:relative;
    /* overflow: hidden; */
    /* height: 48.65vw; */
    /* height: 40vw; */
    /* max-height: 700px; */
  }
  
  section.ShopNow svg.rotated {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    /* max-height: 1500px; */
    stroke-width: 0.1px;
  }
  
  .ShopNow .col {
    width: 40%;
    position: relative;
    min-height: 30vw;
    /* display: flex; */
    /* justify-content: center; */
    align-items: center;
    padding-top: 20px;
  }
  
  .ShopNow .image-col {
    width: 35%;
    position: relative;
    min-height: 40vw;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 8px; */
  }
  
  .ShopNow img {
    max-width: 100%;
    height: 22vw;
    position: absolute;
    max-height: 100%;
  }
  
  .ShopNow article {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content:justify;
    max-width: 100%;
    padding-top: 20%;
  }
  
  .ShopNow article h2 {
    font-family: "Crimson Text Pro", serif;
    font-size: 2.4vw;
    font-weight: 900;
    line-height: 3.95vw;
    margin: 0 0 2px;
    /* max-width: 525px; */
    text-align: center;
    color: #d9ab73;
  }
  
  .ShopNow article p {
    font-family: "Montserrat", sans-serif;
    font-size: 1vw;
    line-height: 1.75vw;
    margin: 0 0 3vw;
    /* max-width: 450px; */
    color: #f2e6d5;
    text-align: center;
  }
  
  .ShopNow article a {
    background-color: rgba(201, 76, 76, 0);
    border-radius: 3px;
    line-height: 3.2vw;
    color: #d9ab73;
    font-family: "Montserrat", sans-serif;
    font-size: 1.14vw;
    font-weight: 900;
    text-transform: uppercase;
    text-align: center;
    max-width: 14vw;
    max-height: 3.45vw;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
    padding: 0 20px 0 20px;
    border: 2px solid #d9ab73;
    border-radius: 12px;
  }
  
  .ShopNow article a:hover {
    background-color: #d9ab73;
    color: #232323;
    cursor: pointer;
    text-decoration: none;
  }
  
  .ShopNow .mobile-only {
    display: none;
  }
  
  .ShopNow .non-mobile-only {
    display: block;
  }
  
  
  /* HOME: 3 MAIN PRODUCTS */
  
  @import url('https://fonts.googleapis.com/css2?family=Crimson+Text&family=Montserrat:wght@200&display=swap');
  
  .threeMainProducts a {
    text-decoration: none;
  }
  
  .threeMainProducts a:hover {
    text-decoration: underline;
    text-decoration-color: #d9ab73;
  }
  .threeMainProducts {
    position: relative;
    background-color: #181818;
  
    padding-top: 40px;
    padding-bottom: 20px;
  }
  
  .threeImageCarousel {
    position: relative;
    /* padding-left: 10%;
    padding-right: 10%; */
    text-align: center;
  
    margin-left: 15px;
  }
  
  /* .productSquareColumn{
    padding-left:10px;
    padding-right:10px;
  
  } */
  
  .threeProductCategoryImages {
    position: relative;
    /* padding: 50%; */
  
    /* padding-top: 40px; */
    padding-left: 50px;
    padding-right: 50px;
    /* padding-bottom: 40px; */
  
    width: 100%;
  }
  
  .threeProductCategoryHeadline {
    font-family: 'Crimson Text Pro', serif;
    font-weight: bold;
    font-size: 2vw;
    color: rgb(217,171,115);
  }
  
  .flowerBox {
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  
  .edibleBox {
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  
  .concentrateBox {
    position: relative;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
  }
  
  #flowerImage {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  #flowerImageHover {
    position: absolute;
    top: 0px; 
    left: 0px;
  }
  
  #edibleImage {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  #edibleImageHover {
    position: absolute;
    top: 0px; 
    left: 0px;
  }
  
  #concentrateImage {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
  }
  
  #concentrateImageHover {
    position: absolute;
    top: 0px; 
    left: 0px;
  }
  
  .overlay {
    display: inline-block;
    width: 100%;
    height: auto;
    opacity: 0;
    transition: .5s ease;
  }
  
  .flowerBox .overlay:hover {
    opacity: 1;
  }
  
  .edibleBox .overlay:hover {
    opacity: 1;
  }
  
  .concentrateBox .overlay:hover {
    opacity: 1;
  }
  
  
  
  /* HOME: CATEGORY SECTION */
  
  section.Categories {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    
    position: relative;
    /* overflow: hidden;
    height: 50vh; */
    max-height: 700px;
    width: 100%;
  }
  
  section.Categories .yumaBorder {
    width: 100%
  }
  
  section.Categories svg.rotated {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    stroke-width: 0.1px;
    padding-bottom: 20px;
  }
  
  section.Categories .article-container {
    width: 100%;
    
    padding-top: 5vw;
    padding-bottom: 5vw;
    padding-left: 5vw;
    padding-right: 5vw;
  
    z-index: 10;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  section.Categories .article-container article {
    min-width: 15.5vw;
  
    justify-content: center;
  }
  
  .yellowText {
    color: #d9ab73;
  }
  
  .lightText {
    color: #f2e6d5 !important;
  }
  
  section.Categories .mobile-only {
    display: none;
  }
  
  input[type="radio"],
  input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
  }
  input[type="checkbox"],
  input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  
  section.Categories article header {
    font-size: 4.1vw;
    font-weight: 200;
    line-height: 5.2vw;
    text-align: center;
    width: 100%;
    display: block;
    font-family: "Crimson Text Pro", serif;
  }
  
  section.Categories article ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }
  
  section.Categories article ul li {
    display: block;
    text-align: center;
  }
  
  section.Categories article ul li a {
    color: #f2e6d5 !important;
    font-size: 1.11vw;
    font-weight: 400;
    line-height: 4vh;
    text-align: center;
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
  }
  
  #svgCategorySeparator {
    width: 100px;
    /* height: 100%; */
    margin-top: 1vh;
    margin-bottom: 1vh;
  }

  /* top: calc(10vh - 20px); */
  /* .verticalLineSeperator {
    width: 20px;
    height: 100px;
    position: relative;
    top: calc(10% + 10px);
    left: calc(10% + 10px);
    border: 1px solid #d9ab73;
    opacity: .5;

    margin-top: 1vh;
    margin-bottom: 1vh;
  } */

  /* .verticalLineSeperator::after {
    position: absolute;
    top: -40px;
    bottom: -40px;
    left: calc(50% - 1px);
    background: #d9ab73;
    width: 2px;
    content: '';
  } */
  
  #lineBox {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 100%;
    stroke-width: 0.5;
    z-index: 10;
  }
  #list-items {
    margin: auto;
  }
  
  
  
  /* HOME: LOYALTY PROGRAM */
  
  section.app-download {
    background-image: url("./phoneApp-Background2.png");
    /* background-color: #ffffff; */
    background-position: right top; 
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    height: 48.65vw;
    max-height: 700px;
  
    background-color: #181818 !important;
    /* outline: 10px solid #181818 !important; */
    outline-offset: -5px;
    /* border-top: solid #232323 10px; */
  }
  
  section.app-download div.content {
    display: flex;
    color: #d9ab73;
    /* opacity: 100%; */
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    margin-left: 15%;
    width: 30vw;
    max-width: 535px;
    z-index: 10;
  }
  
  section.app-download a.get-app-link {
    background-color: rgba(201, 76, 76, 0);
    border-radius: 3px;
    line-height: 3.2vw;
    color: #d9ab73;
    font-family: "Montserrat", sans-serif;
    font-size: 1vw;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    max-width: 14vw;
    max-height: 3.45vw;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
    padding: 0 20px 0 20px;
    border: 2px solid #d9ab73;
    border-radius: 12px;
  }
  
  section.app-download a.get-app-link:hover {
    background-color: #d9ab73 !important;
    color: #232323 !important;
    cursor: pointer !important;
    text-decoration: none;
  }
  
  section.app-download div.content h2 {
    font-family: "Crimson Text Pro", serif;
    font-size: 2.4vw;
    font-weight: 900;
    line-height: 3.95vw;
    /* margin: 0 0 2px; */
    margin-bottom: 10px;
    max-width: 525px;
    text-align: center;
    color: #d9ab73;
  }
  
  section.app-download div.content p {
    font-family: "Montserrat", sans-serif;
    font-size: 1vw;
    line-height: 1.75vw;
    margin: 0 0 3vw;
    color: #f2e6d5;
    text-align: center;
  }
  
  section.app-download div.content h2.desktop-only {
    display: block;
  }
  
  section.app-download div.content h2.non-desktop-only {
    display: none;
  }
  
  section.app-download .mobile-only {
    display: none;
  }
  
  section.app-download div.img-container {
    width: 50%;
    z-index: 10;
    position: relative;
    pointer-events: none;
  }
  
  section.app-download div.img-container img {
    position: absolute;
    bottom: -20%;
    right: 10%;
    width: 47.5vw;
    height: 47vw;
    max-height: 700px;
    pointer-events: none;
    vertical-align: middle;
    border: 0;
    
  }
  
  svg:not(:root) {
  overflow: hidden;
  }
  
  section.app-download svg.rotated {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    stroke-width: 0.1px;
    /* opacity: 97%; */
  }
  
  @media screen and (min-width: 576px) {
  
    #showOnMobile {
        display: none;
    } 
  
  }
  
  @media screen and (max-width: 576px) {
  
    #hideOnMobile {
        display: none;
    }
  
    #showOnMobile {
        display: block;
    } 
  
    section.ShopNow {
        /* height: 70vh !important; */
        width: 100%;
        text-align: center;
        flex-direction: column;
        padding-top: 40px;
        max-height: none;
        padding-bottom: 40px;
    }
  
    .ShopNow .col {
        width: 100%;
        padding-top: 0px;
        /* margin-bottom: 10%; */
    }
    .ShopNow .image-col {
        width: 100%;
        min-height: 30vh;
    }
  
    .ShopNow img {
        max-height: auto;
        max-width: 100%;
        margin: 0 auto;
        height: 20vh;
    }
    .ShopNow .top-image {
        /* margin-top: 12vh; */
    }
    .ShopNow .bottom-image {
        padding-bottom: 12vh;
    }
  
    .ShopNow article {
        top: 0;
        padding: 0;
        max-width: none !important;
        align-items: center;
    }
  
    .ShopNow article h2 {
        font-size: 8.5vw;
        line-height: 14.5vw;
    }
  
    .ShopNow article p {
        text-align: center;
        max-width: 75vw;
        font-size: 4.5vw;
        line-height: 6vw;
    }
  
    .ShopNow article a {
        min-width: 20vw;
        max-width: 100vw;
        max-height: none;
        line-height: 11vw;
        font-size: 3vw;
        padding: 0 20px;
  
    }
  
    section.Categories {
        display: none;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: flex-start;
        padding: 0;
    }
    
    section.Categories article {
        position: relative;
    }
    
    section.Categories .mobile-only {
        display: block;
    }
    
    section.Categories article input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        z-index: 10;
        max-height: 62px;
    }
    
    section.Categories article input {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        opacity: 0;
        z-index: 10;
        max-height: 62px;
    }
    section.Categories article header i {
        position: absolute;
        right: 28px;
        top: 24px;
    }
    
    section.Categories article header {
        background: #f7f7f7;
        border-bottom: solid 2px #d8d8d8;
        font-size: 24px;
        line-height: 34px;
        padding: 13px 28px;
        text-align: left;
        text-transform: uppercase;
        cursor: pointer;
    }
    
    section.Categories article header i {
        position: absolute;
        right: 28px;
        top: 24px;
    }
   
    section.Categories article ul {
        display: none;
        background-color: #ffffff;
    }
  
    section.Categories article ul li {
        padding: 18px 0 0 30px;
    }
  
    section.Categories article ul li a {
        text-align: left;
        width: 100%;
        display: block;
        font-size: 4.5vw;
        line-height: 6vw;
    }
  
    section.Categories article input:checked ~ ul {
        display: block;
    }
  
    section.Categories article ul li a {
        text-align: left;
        width: 100%;
        display: block;
        font-size: 4.5vw;
        line-height: 6vw;
    }
  
    section.Categories article ul .all-link {
        text-align: left;
        padding: 0 0 15px 0px;
        justify-content: flex-start;
    }
  
    section.Categories i.right-arrow {
        width: 3vw;
        height: 4.5vw;
    }
  
    section.app-download {
        height: 35vh !important;
        max-height: none;
        padding-bottom: 10vh;
    }
  
    section.app-download div.content {
        width: auto;
        margin: 40px auto 15px;
    }
  
    section.app-download a.get-app-link {
        min-width: 20vw;
        max-width: 100vw;
        max-height: none;
        line-height: 11vw;
        font-size: 3vw;
        padding: 0 20px;
    }
  
    section.app-download div.content h2 {
        font-size: 6.5vw;
        line-height: 9vw;
    }
  
    section.app-download div.content h2.desktop-only {
        display: none;
    }
  
    section.app-download div.content h2.non-desktop-only {
        display: block;
        text-align: center;
    }
  
    section.app-download div.content p {
        margin: 0 auto 21px;
        max-width: 370px;
        text-align: center;
    }
  
    section.app-download div.content p {
        text-align: center;
        margin: 0 auto 21px;
        max-width: 90%;
        font-size: 4.5vw;
        line-height: 6vw;
    }
  
    section.app-download div.img-container {
        width: 100%;
        height: 100%;
    }
  
    section.app-download div.img-container img {
        left: 34vw;
    }
  
    section.app-download .non-mobile-only {
        display: none;
    }
  
    section.app-download .mobile-only {
        display: block;
    }
  
    section.app-download {
        background-image: url("./phoneApp-Background-mobile-skinnytall.png");
        /* background-color: #ffffff; */
        background-position: left; 
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        height: 48.65vw;
        max-height: 700px;
    }
   
  }
  
  
  
  /* MOBILE HEIGHT CONTROLS */
  
  @media screen and (max-width: 576px) {
  
    #desktopHeroView {
        display: none;
    }
    
    #mobileTabletContentHolder {
        display: block !important;
    }
    #mobileHeroView {
        display: block;
    }
    #tabletHeroOutline {
        display: none !important;
    }
  
    #mobileHeroOutline425 {
        display: block !important;
    }
    #mobileHeroOutline375 {
        display: none !important;
    }
    #mobileHeroOutline320 {
        display: none !important;
    }
  
  
    section.hero {
        /* padding-top: 25px; */
        /* height: 100vh !important; */
        
    }
  
    
  
    .displayLogo {
        padding-top: 30px;
    }
  
    .displayImage {
        width: 50% !important;
        
        margin-top: 2vw;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }
  
    .displayButtonContainer {
        margin-top: 20px;
        margin-bottom: 80px;
    }
  
    .storeFinderButton {
        margin-top: 2vh !important;
        margin-bottom: 6vh !important;
    
        background-color: rgba(201, 76, 76, 0);
        border-radius: 3px;
        line-height: 6vw !important;
        color: #d9ab73;
  
        font-family: "Montserrat", sans-serif;
        font-size: 4vw !important;
        font-weight: 900;
        text-transform: uppercase;
        text-align: center;
  
        max-width: 100vw;
        max-height: 100vw;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        
        /* margin-top: 20px !important;
        margin-bottom: 20px !important; */
        padding: 15px 30px 15px 30px !important;
        border: 1px solid #d9ab73 !important;
        border-radius: 12px;
        text-decoration: none;
    
    }
  
    
  
    section.QuoteBanner .non-mobile-only {
        display: none ;
    }
  
    /* section.QuoteBanner .mobile-only {
        display: initial;
    } */
  
    section.QuoteBanner {
        height: 100% !important;
        background-image: none !important;
    }
  
    section.QuoteBanner.left {
        /* flex-direction: column;
        justify-content: flex-end; */
        /* padding-bottom: 10vw; */
        /* height: 70vh; */
    }
  
    section.QuoteBanner article { 
        /* max-width: 95%; */
        right: 0 !important;
        align-items: center;
        margin: auto;
        text-align: center;
    }
  
    section.QuoteBanner article h2 {
        font-size: 7vw;
        line-height: 14.5vw;
        max-width: none !important;
    }
  
    .QuoteBanner article p {
        text-align: center;
        /* max-width: 75vw; */
        font-size: 4.5vw;
        line-height: 6vw;
    }
  
    section.QuoteBanner article a {
        margin-top: 20px;
        min-width: 20vw;
        max-width: 100vw;
        max-height: none;
        line-height: 11vw;
        font-size: 2vw;
        padding: 0 20px;
    }
  
    section.ShopNow {
        /* height: 112vh !important; */
    }
  
    section.threeMainProducts {
        position: relative;
        background-color: #181818;
    
        padding-top: 10px;
        padding-bottom: 0px;
  
    }
  
    .threeProductCategoryHeadline {
      font-size: 8vw;
      color: rgb(217,171,115);
      padding-bottom: 6vw;
    }
  
    section.ShopNow article p {
        margin: 0 0 10vw;
    }
    
  
    section.app-download {
        height: 70vh !important;
        background-image: url("./phoneApp-Background-mobile-skinnytall.png");
        /* background-color: #f2e6d5; */
        background-position: 0px 0px; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
    }
    
    section.app-download div.content {
        padding: 10px 20px 10px 20px;
        margin-top: 80px;
        justify-content: unset;
    }
  
  }